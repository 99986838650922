import React from 'react';

const Popup = ({ message, onClose, onConfirm }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onClose}>Annuler</button>
                <button onClick={onConfirm}>Confirmer</button>
            </div>
        </div>
    );
};

export default Popup;