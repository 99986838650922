import React, { createContext, useState, useContext } from 'react';

// Créez le contexte utilisateur
const UserContext = createContext();

// Fournisseur de contexte utilisateur
export const UserProvider = ({ children }) => {
    const [userStatus, setUserStatus] = useState('guest'); // 'guest', 'loggedIn', 'subscribed'
    const [crystals, setCrystals] = useState(0); // Initialisez les cristaux à 0 ou à une valeur par défaut

    const login = () => setUserStatus('loggedIn');
    const subscribe = () => setUserStatus('subscribed');

    const isAuthenticated = userStatus !== 'guest'; // Utilisateur authentifié si pas 'guest'

    // Fonctions pour ajouter et déduire des cristaux
    const addCrystals = (amount) => setCrystals((prev) => prev + amount);
    const deductCrystals = (amount) => setCrystals((prev) => prev - amount);

    return (
        <UserContext.Provider value={{ isAuthenticated, crystals, login, subscribe, addCrystals, deductCrystals }}>
            {children}
        </UserContext.Provider>
    );
};

// Hook personnalisé pour utiliser le contexte utilisateur
export const useUser = () => {
    return useContext(UserContext);
};
