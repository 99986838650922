import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { characters } from '../data/chaptersData';
import chapters from '../data/chaptersData';
import ChaptersCard from '../components/ChaptersCard';
import { useUser } from '../hooks/userContext';
import Popup from '../components/Popup'; // Assurez-vous que ce composant existe

function Chapters() {
    const { characterId } = useParams();
    const character = characters.find((char) => char.link === characterId);
    const navigate = useNavigate();

    const { isAuthenticated, crystals, deductCrystals } = useUser() || {}; // Utilisation d'une valeur par défaut vide

    const [filterStatus, setFilterStatus] = useState('all');
    const [filterPart, setFilterPart] = useState('all');
    const [sortOrder, setSortOrder] = useState('asc');
    const [popup, setPopup] = useState(null); // Pour gérer les pop-ups

    const uniqueChapters = Array.from(new Set(chapters.map(chapter => chapter.id)))
        .map(id => chapters.find(chapter => chapter.id === id));

    const filteredChapters = uniqueChapters.filter(chapter => {
        const isStatusMatch = (filterStatus === 'all') ||
            (filterStatus === 'locked' && !chapter.isUnlocked) ||
            (filterStatus === 'unlocked' && chapter.isUnlocked);

        const isPartMatch = (filterPart === 'all') || (chapter.title === filterPart);

        return isStatusMatch && isPartMatch;
    });

    const sortedChapters = filteredChapters.sort((a, b) => {
        return sortOrder === 'asc'
            ? a.releaseDate.localeCompare(b.releaseDate)
            : b.releaseDate.localeCompare(a.releaseDate);
    });

    useEffect(() => {
        const titleElement = document.querySelector('.title');
        if (titleElement && character) {
            titleElement.style.background = character.titleGradient;
            titleElement.style.backgroundClip = 'text';
            titleElement.style.WebkitTextFillColor = 'transparent';
        }

        const handleScroll = () => {
            const filterContainer = document.querySelector('.filter-container');
            const scrollPosition = window.scrollY;
            const stopScrollPosition = 100;

            if (scrollPosition > stopScrollPosition) {
                filterContainer.classList.add('sticky');
            } else {
                filterContainer.classList.remove('sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [character]);

    const handleChapterClick = (chapter) => {
        if (!isAuthenticated) {
            navigate('/login');
        } else if (chapter.isUnlocked) {
            navigate(`/read/${chapter.id}`);
        } else {
            setPopup({
                type: 'locked',
                chapter: chapter
            });
        }
    };

    const handleUnlockChapter = (chapter) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }
        if (crystals < chapter.unlockCost) {
            navigate('/shop');
            return;
        }
        setPopup({
            type: 'confirmUnlock',
            chapter: chapter
        });
    };

    const confirmUnlock = (chapter) => {
        deductCrystals(chapter.unlockCost); // Déduire les cristaux
        chapter.isUnlocked = true; // Marquer le chapitre comme débloqué
        setPopup(null); // Fermer la pop-up
    };

    return (
        <section>
            {popup && <Popup popup={popup} onConfirm={() => confirmUnlock(popup.chapter)} onClose={() => setPopup(null)} />}
            <div className="chapters-section" id="chapters">
                <div className="text-container">
                    <h1 className="responsive uppercase nomargin">Les chapitres</h1>
                </div>
                <div className="chapters-container">
                    <div className="filter-container">
                        <select onChange={(e) => setFilterPart(e.target.value)} value={filterPart}>
                            <option value="all">Tous les Maîtres</option>
                            {characters.map((char) => (
                                <option key={char.link} value={char.title}>{char.title}</option>
                            ))}
                        </select>
                        <select onChange={(e) => setFilterStatus(e.target.value)} value={filterStatus}>
                            <option value="all">Tous les chapitres</option>
                            <option value="unlocked">Chapitres débloqués</option>
                            <option value="locked">Chapitres verrouillés</option>
                        </select>
                        <select onChange={(e) => setSortOrder(e.target.value)} value={sortOrder}>
                            <option value="asc">Dernières publications</option>
                            <option value="desc">Premières publications</option>
                        </select>
                        <button
                            onClick={() => {
                                setFilterStatus('all');
                                setFilterPart('all');
                                setSortOrder('asc');
                            }}
                            className="reset-button"
                        >
                            Réinitialiser
                        </button>
                    </div>
                    <div className="chapters-list">
                        {sortedChapters.map((chapter) => (
                            <ChaptersCard
                                key={chapter.id}
                                thumbnail={chapter.thumbnail}
                                title={chapter.title}
                                chapNumber={chapter.chapNumber}
                                headline={chapter.headline}
                                releaseDate={chapter.releaseDate}
                                currentChaptersIndex={chapters.indexOf(chapter)}
                                isUnlocked={chapter.isUnlocked}
                                onClick={() => handleChapterClick(chapter)}
                                onUnlock={() => handleUnlockChapter(chapter)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Chapters;
