import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import validator from 'validator';

export const ContactUs = () => {
    const form = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emailSentStatus, setEmailSentStatus] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [messageError, setMessageError] = useState(null);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const userName = form.current.elements.user_name.value.trim();
        const userEmail = form.current.elements.user_email.value.trim();
        const userMessage = form.current.elements.message.value.trim();

        let hasError = false;

        if (userName === '') {
            setNameError('Ce champ est obligatoire.');
            hasError = true;
        } else {
            setNameError(null);
        }

        if (!validator.isEmail(userEmail)) {
            setEmailError('Veuillez entrer un email valide.');
            hasError = true;
        } else {
            setEmailError(null);
        }

        if (userMessage === '') {
            setMessageError('Ce champ est obligatoire.');
            hasError = true;
        } else {
            setMessageError(null);
        }

        if (hasError) {
            setIsSubmitting(false);
            return;
        }

        emailjs
            .sendForm('service_mwim3fl', 'template_d1yyioh', form.current, 'lgMnUX8CZ4qZo1IeK')
            .then(
                () => {
                    setEmailSentStatus('success');
                    console.log('Message envoyé!');
                    form.current.reset();
                },
                (error) => {
                    setEmailSentStatus('error');
                    console.log('Erreur lors de l\'envoi du message', error.text);
                }
            )
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <section>
            <div className="text-container">
                <h1 className="responsive uppercase nomargin">Contactez-moi</h1>
                <p>Que vous souhaitiez m'envoyer un message d'encouragement ou simplement partager vos impressions, n'hésitez pas à m'écrire. Vos mots sont ce qui me donne la force de continuer KURO NO MASTER.</p>
                <p className="bold lg">Votre soutien compte beaucoup pour moi.</p>
            </div>
            <div className="form-container">
                <form ref={form} onSubmit={sendEmail} className="input-field">
                    <label>Votre nom</label>
                    <input type="text" name="user_name" />
                    {nameError && <div className="orange italic">{nameError}</div>}
                    <label>Votre email</label>
                    <input type="email" name="user_email" />
                    {emailError && <div className="orange italic">{emailError}</div>}
                    <label>Votre message</label>
                    <textarea name="message"></textarea>
                    {messageError && <div className="orange italic">{messageError}</div>}
                    <button type="submit" disabled={isSubmitting} className="primary-button">
                        {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
                    </button>
                    {emailSentStatus === 'success' && <div className="bold lg">Merci ! Votre message a bien été reçu !</div>}
                    {emailSentStatus === 'error' && <div className="bold lg">Une erreur est survenue lors de l'envoi de votre message...</div>}
                </form>
            </div>
        </section>
    );
};

export default ContactUs;