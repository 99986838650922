function getCharacterColor(charNumber) {
  const colors = [
    'linear-gradient(-90deg, #00BBFF 0%, #6255BB 100%)',
    'linear-gradient(-90deg, #6D3081 0%, #43349D 100%)',
    'linear-gradient(-90deg, #C5280E 0%, #A4003D 100%)',
    'linear-gradient(-90deg, #00BB00 0%, #005F3E 100%)',
    'linear-gradient(-90deg, #FAB500 0%, #D95D00 100%)',];
  return colors[charNumber % colors.length];
}

// Function to format release date
function formatReleaseDate(dateString) {
  const dateObj = new Date(dateString);
  const formatter = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long' });
  return formatter.format(dateObj);
}

const characters = [
  {
    charNumber: 0,
    link: 'lucien-chang',
    name: 'Lucien Chang',
    title: 'Maître du Temps',
    cardImage: '../images/character-cards/lucien-chang.jpg',
    imagePage: '../images/character-cards/lucien-chang-character-page.jpg',
    cardGradient: 'linear-gradient(0deg, #00BBFF 15%, #6255BB00 40%)',
    titleGradient: 'linear-gradient(-90deg, #00BBFF 0%, #6255BB 100%)',
    description: 'Créatif passionné issu de la communauté Hmong. Lucien Chang est tiraillé entre suivre les traditions ou tracer sa propre voie, il peut manipuler le cours du temps pour découvrir qui il est vraiment.',
    descriptionPitch: 'En tant que Maître du Temps, il sera amené, durant son existence, à changer le monde, d’une manière ou d\'une autre.',
  },
  {
    charNumber: 1,
    link: 'sonia-bellevue',
    name: 'Sonia Bellevue',
    title: 'Maître de l\'Espace',
    cardImage: '../images/character-cards/sonia-bellevue.jpg',
    imagePage: '../images/character-cards/sonia-bellevue-character-page.jpg',
    cardGradient: 'linear-gradient(0deg, #6D3081 15%, #43349D00 40%)',
    titleGradient: 'linear-gradient(-90deg, #6D3081 0%, #43349D 100%)',
    description: 'Discrète, taciturne et sensible à la musique, Sonia Bellevue est une jeune femme d’origine russe, capable de manipuler l’espace. Sa mère adoptive l’a inspirée pour devenir une artiste, mais elle est hantée par son passé.',
    descriptionPitch: 'En tant que Maître de l\'Espace, elle sera amenée, durant son existence, à changer le monde, d’une manière ou d\'une autre.',
  },
  {
    charNumber: 2,
    link: 'cole-de-kern',
    name: 'Cole de Kern',
    title: 'Maître du Destin',
    cardImage: '../images/character-cards/cole-de-kern.jpg',
    imagePage: '../images/character-cards/cole-de-kern-character-page.jpg',
    cardGradient: 'linear-gradient(0deg, #C5280E 15%, #A4003D00 40%)',
    titleGradient: 'linear-gradient(-90deg, #C5280E 0%, #A4003D 100%)',
    description: 'Fils cadet de famille bretonne aisée mais tout aussi conflictuelle, Cole de Kern a la capacité de contrôler les évènements, il aspire à comprendre les relations humaines pour résoudre ses conflits internes.',
    descriptionPitch: 'En tant que Maître du Destin, il sera amené, durant son existence, à changer le monde, d’une manière ou d\'une autre.',
  },
  {
    charNumber: 4,
    link: 'scarlett-o-keeffe',
    name: 'Scarlett O\'Keeffe',
    title: 'Maître des Sens',
    cardImage: '../images/character-cards/scarlett-o-keeffe.jpg',
    imagePage: '../images/character-cards/scarlett-o-keeffe-character-page.jpg',
    cardGradient: 'linear-gradient(0deg, #00BB00 15%, #005F3E00 40%)',
    titleGradient: 'linear-gradient(-90deg, #00BB00 0%, #005F3E 100%)',
    description: 'Jeune femme irlandaise malicieuse des milieux ruraux, Scarlett O’Keeffe a été victime un grave accident de la route qui l\'a motivée à vouloir devenir médecin. Elle est capable de contrôler les sens perceptifs.',
    descriptionPitch: 'En tant que Maître des Sens, elle sera amenée, durant son existence, à changer le monde, d’une manière ou d\'une autre.',
  },
  {
    charNumber: 4,
    link: 'remi-muller',
    name: 'Remi Müller',
    title: 'Maître de la Vie',
    cardImage: '../images/character-cards/remi-muller.jpg',
    imagePage: '../images/character-cards/remi-muller-character-page.jpg',
    cardGradient: 'linear-gradient(0deg, #FAB500 15%, #D95D0000 40%)',
    titleGradient: 'linear-gradient(-90deg, #FAB500 0%, #D95D00 100%)',
    description: 'Plongé tôt dans les désillusions de la justice américaine, Remi Müller s’est détaché de son passé en reniant ses origines. Disposant d\'un très large réseau, il vagabonde chez ses amis. Remi Müller peut manipuler la vie, jusqu\'à la mort.',
    descriptionPitch: 'En tant que Maître de la Vie, il sera amené, durant son existence, à changer le monde, d’une manière ou d\'une autre.',
  },
];

const chapters = [
  {
    id: 0,
    charNumber: characters[0].charNumber,
    chapNumber: 1,
    chapPath: 1,
    headline: "L'ère du temps",
    name: characters[0].name,
    title: characters[0].title,
    releaseDate: formatReleaseDate("2024-09-19"),
    thumbnail: "../images/illustrations/lucien-l-ere-du-temps.jpg",
    color: getCharacterColor(1),
    isUnlocked: true,
    panel: [
      '/images/chap/lucien/1-1/img1-1.jpg',
      '/images/chap/lucien/1-1/img1-2.jpg',
      '/images/chap/lucien/1-1/img1-3.jpg',
      '/images/chap/lucien/1-1/img1-4.jpg',
      '/images/chap/lucien/1-1/img1-5.jpg',
      '/images/chap/lucien/1-1/img1-6.jpg',
      '/images/chap/lucien/1-1/img1-7.jpg'
    ],
  },
  {
    id: 1,
    charNumber: characters[1].charNumber,
    chapNumber: 1,
    chapPath: 1,
    headline: "Rêves ou souvenirs",
    name: characters[1].name,
    title: characters[1].title,
    releaseDate: formatReleaseDate("2024-12-20"),
    thumbnail: "../images/illustrations/sonia-et-alina-belinsky.jpg",
    color: getCharacterColor(2),
    panel: [
      '/images/chap/lucien/1-1/img1-8.jpg',
      '/images/chap/lucien/1-1/img1-9.jpg',
      '/images/chap/lucien/1-1/img1-10.jpg',
      '/images/chap/lucien/1-1/img1-11.jpg',
      '/images/chap/lucien/1-1/img1-12.jpg',
      '/images/chap/lucien/1-1/img1-13.jpg',
      '/images/chap/lucien/1-1/img1-14.jpg'
    ],
  },
  {
    id: 2,
    charNumber: characters[2].charNumber,
    chapNumber: 1,
    chapPath: 1,
    headline: "Karthasis",
    name: characters[2].name,
    title: characters[2].title,
    releaseDate: formatReleaseDate("2024-01-31"),
    thumbnail: "../images/illustrations/lucien-sonia-cole-marchent.jpg",
    color: getCharacterColor(3),
    panel: [
      '/images/chap/lucien/1-1/img1-15.jpg',
      '/images/chap/lucien/1-1/img1-16.jpg',
      '/images/chap/lucien/1-1/img1-17.jpg',
      '/images/chap/lucien/1-1/img1-18.jpg',
      '/images/chap/lucien/1-1/img1-19.jpg',
      '/images/chap/lucien/1-1/img1-20.jpg',
      '/images/chap/lucien/1-1/img1-21.jpg'
    ],
  },
  {
    id: 3,
    charNumber: characters[3].charNumber,
    chapNumber: 1,
    chapPath: 1,
    headline: "Continue de courir",
    name: characters[3].name,
    title: characters[3].title,
    releaseDate: formatReleaseDate("2025-07-11"),
    thumbnail: "../images/illustrations/scarlett-outdoor-ete.jpg",
    color: getCharacterColor(4),
    panel: [
      '/images/chap/lucien/1-1/img1-22.jpg',
      '/images/chap/lucien/1-1/img1-23.jpg',
      '/images/chap/lucien/1-1/img1-24.jpg',
      '/images/chap/lucien/1-1/img1-25.jpg',
      '/images/chap/lucien/1-1/img1-26.jpg',
      '/images/chap/lucien/1-1/img1-27.jpg',
      '/images/chap/lucien/1-1/img1-28.jpg'
    ],
  },
  {
    id: 4,
    charNumber: characters[4].charNumber,
    chapNumber: 1,
    chapPath: 1,
    headline: "À murmurer à mère",
    name: characters[4].name,
    title: characters[4].title,
    releaseDate: formatReleaseDate("2025-08-03"),
    thumbnail: "../images/illustrations/remi-ruelle-sombre.jpg",
    color: getCharacterColor(5),
    panel: [
      '/images/chap/lucien/1-1/img1-29.jpg',
      '/images/chap/lucien/1-1/img1-30.jpg',
      '/images/chap/lucien/1-1/img1-31.jpg',
      '/images/chap/lucien/1-1/img1-32.jpg',
      '/images/chap/lucien/1-1/img1-33.jpg',
      '/images/chap/lucien/1-1/img1-34.jpg',
      '/images/chap/lucien/1-1/img1-35.jpg'
    ],
  }
];

export default chapters;
export { getCharacterColor, characters };